import React, { useState, useEffect } from 'react';
import { useWalletClient, useAccount } from 'wagmi';
import { getContract } from 'viem';
import { prizewinnerContractAddress, prizewinnerAbi } from './abi/prizewinnerContractConfig';
import { nftContractAddress } from './abi/nftContractConfig';
import styles from './styles/page.module.css';

const WinnerDisplay = () => {
    const { isConnected } = useAccount();
    const { data: walletClient } = useWalletClient();
    const [winnerTokenId, setWinnerTokenId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const fetchWinnerTokenId = async () => {
        if (!isConnected) {
            setMessage('Please connect your wallet.');
            setShowMessage(true);
            return;
        }

        if (!walletClient) {
            setMessage("No wallet client available. Please connect your wallet.");
            setShowMessage(true);
            return;
        }

        setLoading(true);
        const prizewinnerContract = getContract({
            address: prizewinnerContractAddress,
            abi: prizewinnerAbi,
            client: walletClient,
        });

        try {
            const contestIds = await prizewinnerContract.read.getAllContestIds();
            if (contestIds.length === 0) {
                setMessage("No contest IDs found.");
                setShowMessage(true);
                setLoading(false);
                return;
            }

            const latestContestId = contestIds[contestIds.length - 1];
            const winnerId = await prizewinnerContract.read.getWinnerTokenId([latestContestId]);

            if (winnerId) {
                setWinnerTokenId(winnerId);
                const openseaLink = `https://opensea.io/assets/avalanche/${nftContractAddress}/${winnerId}`;
                window.open(openseaLink, '_blank').focus();
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching winner token ID:', error);
            setMessage('Error fetching winner token ID. Please try again.');
            setShowMessage(true);
            setLoading(false);
        }
    };

    const handleDismissMessage = () => {
        setShowMessage(false);
    };

    return (
        <div>
            {showMessage && (
                <div className={styles.messageOverlay}>
                    <div className={styles.messageBox}>
                        <p>{message}</p>
                        <button onClick={handleDismissMessage} className={styles.closeButton}>Close</button>
                    </div>
                </div>
            )}
            <button className={styles.mobileWinnerButton} onClick={fetchWinnerTokenId} disabled={loading}>
                {loading ? 'Loading...' : 'View Winning NFT'}
            </button>
        </div>
    );
};

export default WinnerDisplay;
