import React, { useState, useEffect, useRef } from 'react';
import styles from './styles/page.module.css';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract, createPublicClient, http } from 'viem';
import { avalanche } from 'viem/chains';

// Import contract configurations
import { nftContractAddress, nftAbi } from './abi/nftContractConfig';
import { millionContractAddress, millionAbi } from './abi/millionContractConfig';

// Import mint function from StakingActions and the component itself
import { handleMintTicketClick } from './staking';
import WinnerDisplay from './winner';
import StakingActions from './staking';

const publicClient = createPublicClient({
  chain: avalanche,
  transport: http(),
});

const TokenPrice = () => {
  const [price, setPrice] = useState(null);

  // Fetch token price from GeckoTerminal API
  const fetchPrice = async () => {
    try {
      const response = await fetch('https://api.geckoterminal.com/api/v2/networks/avax/tokens/0x273819f7d9387ad17cbB77BEA32cd293F9BC8CdE');
      const data = await response.json();
      const priceData = data.data.attributes.price_usd; // Accessing the price in the API response
      setPrice(priceData);
    } catch (error) {
      console.error('Error fetching token price:', error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchPrice();

    // Set up polling every 30 seconds
    const interval = setInterval(() => {
      fetchPrice();
    }, 30000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <p>$Million Price: ${price ? parseFloat(price).toFixed(6) : 'Loading...'} <span className={styles.spaceBetween}></span>
         Next prize:  <span className={styles.highlight}>$5000</span>
      </p>
    </div>
  );
};

const MainSection = () => {
  const { address: userAddress, isConnected } = useAccount();
  const { data: walletClient } = useWalletClient();
  const [nftContract, setNftContract] = useState(null);
  const [millionContract, setMillionContract] = useState(null);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [showOverlay, setShowOverlay] = useState(false); // Overlay visibility state
  const [copySuccess, setCopySuccess] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const topRef = useRef(null);

  const contractAddress = '0x273819f7d9387ad17cbB77BEA32cd293F9BC8CdE';

  // Initialize contracts when the wallet client is available
  useEffect(() => {
    if (walletClient) {
      const nftContractInstance = getContract({
        address: nftContractAddress,
        abi: nftAbi,
        client: walletClient,
      });
      const millionContractInstance = getContract({
        address: millionContractAddress,
        abi: millionAbi,
        client: walletClient,
      });

      setNftContract(nftContractInstance);
      setMillionContract(millionContractInstance);
      console.log('Contracts initialized successfully');
    }
  }, [walletClient]);


  // Handle mint function
  const handleMint = () => {

    if (!isConnected) {
      setMessage('Please connect your wallet.');
      setShowMessage(true);
      return;
    }

    if (!userAddress || !nftContract || !millionContract) {
      setMessage('Please connect your wallet.');
      setShowMessage(true);
      return;
    }
    handleMintTicketClick(userAddress, nftContract, millionContract, setMessage, setShowMessage);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Show success for 2 seconds
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1024);
      setIsPortrait(window.innerHeight > window.innerWidth)
    }
  

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getBackgroundSize = () => {
    return isMobile ? 'contain' : 'cover';
  };

  const handleDismissMessage = () => {
    setShowMessage(false);
  };

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const abbreviatedAddress = `${contractAddress.slice(0, 6)}...${contractAddress.slice(-4)}`;
  
  const handleGifClick = () => {
    setIsPopupOpen(true); // Show the popup when GIF is clicked
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Close popup when close button is clicked
  };

  const Popup = () => (
    <div className={styles.popupBox}>
      <div className={styles.popupContent}>
        <TokenPrice /> {/* Display the token price inside the popup */}
        <button onClick={closePopup} className={styles.closeButton}>Close</button>
      </div>
    </div>
  );


  return (
    <div ref={topRef} className={styles.mainSection}>
    {isMobile && isPortrait ? (
      <>{/* Add overlay toggle on specific slide area */}
    
      {/* Overlay Component */}
      {showOverlay && (
            <div className={styles.overlay} onClick={toggleOverlay}>
            <div className={styles.overlayContent}>
              {/* Scrollable container for overlay content */}
              
              <p>This is <span className={styles.highlight3}>Milly</span>. She’s a normal girl in a messed up world.
                     She works a dead end job for at least 60 hrs a week making just enough to 
                     get by. 
                     <br></br><br></br>

                     Social life? (lol) What social life? Does her pet qualify as a friend?

                     She can’t afford a home. She can’t afford a car. Her rent keeps going up and up.

                     She feels like she can’t win at life! Isn’t there a <span className={styles.highlight3}>Milly</span> in ALL OF US?<br></br><br></br> 

                     The <span className={styles.highlight}>$Million</span> token is a crypto like any other crypto. 
                     But there’s one special thing about <span className={styles.highlight}>$Million</span>. 
                     You can use it to buy a <span className={styles.highlight3}>Milly</span> NFT for a chance to win <span className={styles.highlight}>$1,000,000</span>.
                     No that’s not a typo.  Hold <span className={styles.highlight3}>Milly</span> for a chance to win 1 MILLION DOLLARS!</p>
                  
                  <p><span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span> is the first NFT in the history of crypto to do this.
                     Most NFTs are just digital art. But <span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span> NFTs also give its owners the chance to win.
                     Each <span className={styles.highlight3}>Milly</span> has a unique story embedded into the back of the NFT.
                     Any <span className={styles.highlight3}>Milly</span> can win. Every <span className={styles.highlight3}>Milly</span> has equal opportunity.</p> <br></br>

                  <p className={styles.leftAligned}> 
                     1. You need 100,000 <span className={styles.highlight}>$Million</span> tokens to get a <span className={styles.highlight3}>Milly</span>.<br></br><br></br>
                     2. You can only <span className={styles.highlight}>BUY or HOLD</span> Up To 105,000 <span className={styles.highlight}>$Million</span> tokens at a time.<br></br><br></br>
                     3. This makes the system fair.<br></br><br></br>
                     4. All you have to do is hold your <span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span>.<br></br><br></br>
                     5. You can get more <span className={styles.highlight3}>Millys</span> to increase your chances.</p><br></br>

                  <p className={styles.leftAligned}>
                    The blockchain will automatically pick a random <span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span>.<br></br><br></br>

                     RULES<br></br>
                     Payouts will happen based on the price of <span className={styles.highlight}>$Million</span> token<br></br><br></br>

                     FIRST PAYOUT<br></br>
                      <span className={styles.highlight}>$5,000</span> when <span className={styles.highlight}>$Million</span> reaches 0.001<br></br><br></br>

                     SECOND PAYOUT<br></br> 
                      <span className={styles.highlight}>$25,000</span> when <span className={styles.highlight}>$Million</span> reaches 0.01<br></br><br></br>

                     THIRD PAYOUT<br></br> 
                      <span className={styles.highlight}>$100,000</span> when <span className={styles.highlight}>$Million</span> reaches  0.10<br></br><br></br>

                     FOURTH PAYOUT<br></br> 
                      <span className={styles.highlight}>$250,000</span> when <span className={styles.highlight}>$Million</span> reaches 0.50<br></br><br></br>
 
                     FIFTH PAYOUT<br></br>                      
                      <span className={styles.highlight}>$1,000,000</span> when <span className={styles.highlight}>$Million</span> reaches 1.00</p><br></br>

                      <p className={styles.leftAligned}>
                      <span className={styles.highlight2}>How do I get a </span><span className={styles.highlight3}>Milly</span><span className={styles.highlight2}>?</span><br></br><br></br>
                      
                      1. Connect Wallet<br></br><br></br>
                      2. Click the <a
                         href="https://tinyurl.com/2bytw4vh" onClick={(e) => e.stopPropagation()}
                         target="_blank"
                         rel="noopener noreferrer"
                        className={styles.mobileBuyLink}>
                        <span className={styles.highlight}>BUY</span></a> button on this app<br></br><br></br>
                      3. Get Avax from an exchange. You need enough to buy 100,000 <span className={styles.highlight}>$Million</span> tokens<br></br><br></br>
                      4. You can buy a MAX of 105,000 <span className={styles.highlight}>$Million</span> tokens<br></br><br></br>
                      5. Press Mint to mint a <span className={styles.highlight3}>Milly</span></p>
                         If you just want to trade the <span className={styles.highlight}>$Million</span> token you can do that too!
                  </div>
              </div>
          )}

      
        <div className={styles.connectWalletButtonContainer}>
        <ConnectButton.Custom>
          {({ account, openConnectModal, openAccountModal, mounted }) => (
            <button
              onClick={account ? openAccountModal : openConnectModal}
              className={styles.customConnectButton}
              disabled={!mounted}
            >
              {account ? `${account.address.slice(0, 6)}...${account.address.slice(-4)}` : 'Connect Wallet'}
            </button>
          )}
        </ConnectButton.Custom>
        </div>

        {/* Images */}
        <img src="/images/site/6.png" alt="Slide 6" className={styles.slideImage} />
        <img src="/images/site/7.png" alt="Slide 7" className={styles.slideImage} />
        <div className={styles.slideContainer}>
        <img src="/images/site/8.png" alt="Slide 8" className={styles.slideImage} />
        <button 
            onClick={toggleOverlay} 
            className={styles.overlayToggle}
            aria-label="More Info"
        >
        </button>
        <img  src="/images/siteicons/clicky.gif" alt="Click hand" className={styles.clickyImage}/>
        </div>

       {/*} <div className={styles.mobileButtonContainer}>
        <button 
            onClick={() => window.open('https://tinyurl.com/2bytw4vh', "_blank", 'noopener,noreferrer')}
            className={styles.customBuyButton}>
            Buy $Million
        </button> 
        </div>*/}

        <div className={styles.iframeContainer}>
          <iframe
            style={{
              width: '90%',
              maxWidth: '470px',
              height: 'auto',
              minHeight: '500px',
              marginBottom: '30px',
              bottom: '130px',
              border: '6px solid #29f529',
              borderRadius: '50px',
              position: 'relative',
            }}
            frameborder="0"
            title="Token Swap Widget"
            allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.xyz/embed/trade?swapDisabled=false&swapNetwork=avalanche&swapToTokenAddress=0x273819f7d9387ad17cbb77bea32cd293f9bc8cde&swapLockToToken=false&onRampDisabled=false&onRampNetwork=avalanche&onRampAsDefault=false&onRampTokenAddress=0x273819f7d9387ad17cbb77bea32cd293f9bc8cde&network=eth&lightMode=true&backgroundColor=transparent&miniappIntent=swap"
          />
        </div>

        <div className={styles.mobileButtonContainer}>
          <img src="/images/siteicons/mobilemilly1.png" alt="Milly Icon" className={styles.mobileMilly1}></img>
          <button onClick={handleMint} className={styles.customMintButton}>
            Mint
          </button>
          <img src="/images/siteicons/mobilemilly2.png" alt="Milly Icon" className={styles.mobileMilly2}></img>
        </div>
       
       <div className={styles.lotteryContainer}>
          <img
            src="/images/siteicons/lottery.gif"
            alt="Lottery Drawing"
            style={{
              maxWidth: '100%',
              maxHeight: '200px',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
              bottom: '150px',
            }}
            onClick={handleGifClick}
            className={styles.clickableGif}
          />
        </div>
        <WinnerDisplay />
        {/* If Popup is open, show the token price popup */}
        {isPopupOpen && <Popup />}

        <div className={styles.mobileFooterContainer}>
          <img src="/images/siteicons/docs.png" alt="Milly Docs" className={styles.docsImage}></img>
           <a href="https://docs.millimilly.com" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink1}`}aria-label="Whitepaper" ></a>
            <img src="/images/siteicons/clean.png" alt="Hundred" className={styles.cleanImage}></img>
             <a href="https://hundredcoin.io" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink4}`} aria-label="Hundred Coin">
              </a>
              <img src="/images/siteicons/smallavax.png" alt="Snowtrace" className={styles.avaxImage}></img>
             <a href="https://snowtrace.io/token/0x273819f7d9387ad17cbB77BEA32cd293F9BC8CdE?chainid=43114" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink9}`} aria-label="Snowtrace">
              </a>
             <img src="/images/siteicons/smalltelegram.png" alt="Telegram" className={styles.telegramImage}></img>
            <a href="https://t.me/millioncryptoMilly" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink8}`}aria-label="Telegram" >
           </a>
           <img src="/images/siteicons/cmc.png" alt="Snowtrace" className={styles.cmcImage}></img>
            <a href="https://coinmarketcap.com/dexscan/avalanche/0x42387f4fda8f0839faca0b06c79d42bc15a4a11c" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink10}`} aria-label="Coinmarketcap">
            </a>
        </div>
        
        <div className={styles.contractAddressContainer}>
          <span className={styles.contractAddress}>
            <span className={styles.tokenName}>$Million</span> 
            <span className={styles.address}>{abbreviatedAddress}</span>
          </span>
          <FontAwesomeIcon
            icon={faCopy}
            onClick={handleCopy}
            className={styles.copyIcon}
            title="Copy address"
          />
          {copySuccess && <span className={styles.copySuccess}>Copied!</span>}
        </div>
        <div className={styles.copyrightInfo}>©2024 BYAS All rights reserved.</div>

         {showMessage && (
          <div className={styles.messageOverlay}>
            <div className={styles.messageBox}>
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
              <button onClick={handleDismissMessage} className={styles.closeButton}>Close</button>
            </div>
          </div>
        )}

      </>
      ) : isMobile && !isPortrait ? (
       <>{/* Add overlay toggle on specific slide area */}
    
          {/* Overlay Component */}
        {showOverlay && (
          <div className={styles.overlay} onClick={toggleOverlay}>
          <div className={styles.overlayContent}>
            {/* Scrollable container for overlay content */}
            
                  <p>This is <span className={styles.highlight3}>Milly</span>. She’s a normal girl in a messed up world.
                     She works a dead end job for at least 60 hrs a week making just enough to 
                     get by. 
                     <br></br><br></br>

                     Social life? (lol) What social life? Does her pet qualify as a friend?

                     She can’t afford a home. She can’t afford a car. Her rent keeps going up and up.

                     She feels like she can’t win at life! Isn’t there a <span className={styles.highlight3}>Milly</span> in ALL OF US?<br></br><br></br> 

                     The <span className={styles.highlight}>$Million</span> token is a crypto like any other crypto. 
                     But there’s one special thing about <span className={styles.highlight}>$Million</span>. 
                     You can use it to buy a <span className={styles.highlight3}>Milly</span> NFT for a chance to win <span className={styles.highlight}>$1,000,000</span>.
                     No that’s not a typo.  Hold <span className={styles.highlight3}>Milly</span> for a chance to win 1 MILLION DOLLARS!</p>
                  
                  <p><span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span>    is the first NFT in the history of crypto to do this.
                     Most NFTs are just digital art. But <span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span> NFTs also give its owners the chance to win.
                     Each <span className={styles.highlight3}>Milly</span> has a unique story embedded into the back of the NFT.
                     Any <span className={styles.highlight3}>Milly</span> can win. Every <span className={styles.highlight3}>Milly</span> has equal opportunity.</p> <br></br>

                  <p className={styles.leftAligned}> 
                     1. You need 100,000 <span className={styles.highlight}>$Million</span> tokens to get a <span className={styles.highlight3}>Milly</span>.<br></br><br></br>
                     2. You can only <span className={styles.highlight}>BUY or HOLD</span> Up To 105,000 <span className={styles.highlight}>$Million</span> tokens at a time.<br></br><br></br>
                     3. This makes the system fair.<br></br><br></br>
                     4. All you have to do is hold your <span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span>.<br></br><br></br>
                     5. You can get more <span className={styles.highlight3}>Millys</span> to increase your chances.</p><br></br>

                  <p className={styles.leftAligned}>
                    The blockchain will automatically pick a random <span className={styles.highlight3}><span className={styles.highlight3}>Milly</span></span>.<br></br><br></br>

                     RULES<br></br>
                     Payouts will happen based on the price of <span className={styles.highlight}>$Million</span> token<br></br><br></br>

                     FIRST PAYOUT<br></br>
                      <span className={styles.highlight}>$5,000</span> when <span className={styles.highlight}>$Million</span> reaches 0.001<br></br><br></br>

                     SECOND PAYOUT<br></br> 
                      <span className={styles.highlight}>$25,000</span> when <span className={styles.highlight}>$Million</span> reaches 0.01<br></br><br></br>

                     THIRD PAYOUT<br></br> 
                      <span className={styles.highlight}>$100,000</span> when <span className={styles.highlight}>$Million</span> reaches  0.10<br></br><br></br>

                     FOURTH PAYOUT<br></br> 
                      <span className={styles.highlight}>$250,000</span> when <span className={styles.highlight}>$Million</span> reaches 0.50<br></br><br></br>
 
                     FIFTH PAYOUT<br></br>                      
                      <span className={styles.highlight}>$1,000,000</span> when <span className={styles.highlight}>$Million</span> reaches 1.00</p><br></br>

                      <p className={styles.leftAligned}>
                      <span className={styles.highlight2}>How do I get a </span><span className={styles.highlight3}>Milly</span><span className={styles.highlight2}>?</span><br></br><br></br>

                      1. Connect Wallet<br></br><br></br>
                      2. Click the <a
                         href="https://tinyurl.com/2bytw4vh" onClick={(e) => e.stopPropagation()}
                         target="_blank"
                         rel="noopener noreferrer"
                        className={styles.mobileBuyLink}> 
                        BUY</a>  button on this app<br></br><br></br>
                      3. Get Avax from an exchange. You need enough to buy 100,000 <span className={styles.highlight}>$Million</span> tokens<br></br><br></br>
                      4. You can buy a MAX of 105,000 <span className={styles.highlight}>$Million</span> tokens<br></br><br></br>
                      5. Press Mint to mint a <span className={styles.highlight3}>Milly</span></p>
                         If you just want to trade the <span className={styles.highlight}>$Million</span> token you can do that too!
                </div>
            </div>
        )}

      <div className={styles.connectWalletButtonContainer}>
        <ConnectButton.Custom>
          {({ account, openConnectModal, openAccountModal, mounted }) => (
            <button
              onClick={account ? openAccountModal : openConnectModal}
              className={styles.customConnectButton}
              disabled={!mounted}
            >
              {account ? `${account.address.slice(0, 6)}...${account.address.slice(-4)}` : 'Connect Wallet'}
            </button>
          )}
        </ConnectButton.Custom>
      </div>

          <img src="/images/site/6.png" alt="Slide 6" className={styles.slideImage} />
          <img src="/images/site/7.png" alt="Slide 7" className={styles.slideImage} />
          <div className={styles.slideContainer}>
          <img src="/images/site/8.png" alt="Slide 8" className={styles.slideImage} />
          <button 
            onClick={toggleOverlay} 
            className={styles.overlayToggle}
            aria-label="More Info"
          >
          </button>
          <img  src="/images/siteicons/clicky.gif" alt="Click hand" className={styles.clickyImage}/>
          </div>

      <div className={styles.mobileButtonContainer}>
        <button 
            onClick={() => window.open('https://tinyurl.com/2bytw4vh', "_blank", 'noopener,noreferrer')}
            className={styles.customBuyButton}>
            Buy $Million
        </button> 
        </div>

        <div className={styles.mobileButtonContainer}>
          <img src="/images/siteicons/mobilemilly1.png" alt="Milly Icon" className={styles.mobileMilly1}></img>
          <button onClick={handleMint} className={styles.customMintButton}>
            Mint
          </button>
          <img src="/images/siteicons/mobilemilly2.png" alt="Milly Icon" className={styles.mobileMilly2}></img>
        </div>
        
        <div className={styles.lotteryContainer}>
          <img
            src="/images/siteicons/lottery.gif"
            alt="Lottery Drawing"
            style={{
              maxWidth: '100%',
              maxHeight: '200px',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
              bottom: '150px',
            }}
            onClick={handleGifClick}
            className={styles.clickableGif}
          />
        </div>
        <WinnerDisplay />
        {/* If Popup is open, show the token price popup */}
        {isPopupOpen && <Popup />}

        <div className={styles.mobileFooterContainer}>
          <img src="/images/siteicons/docs.png" alt="Milly Docs" className={styles.docsImage}></img>
           <a href="https://docs.millimilly.com" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink1}`}aria-label="Whitepaper" ></a>
            <img src="/images/siteicons/clean.png" alt="Hundred" className={styles.cleanImage}></img>
             <a href="https://hundredcoin.io" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink4}`} aria-label="Hundred Coin">
              </a>
              <img src="/images/siteicons/smallavax.png" alt="Snowtrace" className={styles.avaxImage}></img>
             <a href="https://snowtrace.io/token/0x273819f7d9387ad17cbB77BEA32cd293F9BC8CdE?chainid=43114" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink9}`} aria-label="Snowtrace">
              </a>
             <img src="/images/siteicons/smalltelegram.png" alt="Telegram" className={styles.telegramImage}></img>
            <a href="https://t.me/millioncryptoMilly" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink8}`}aria-label="Telegram" >
           </a>
           <img src="/images/siteicons/cmc.png" alt="Snowtrace" className={styles.cmcImage}></img>
            <a href="https://coinmarketcap.com/dexscan/avalanche/0x42387f4fda8f0839faca0b06c79d42bc15a4a11c" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink10}`} aria-label="Coinmarketcap">
            </a>
        </div>
        

        <div className={styles.contractAddressContainer}>
          <span className={styles.contractAddress}>
            <span className={styles.tokenName}>$Million</span> 
            <span className={styles.address}>{abbreviatedAddress}</span>
          </span>
          <FontAwesomeIcon
            icon={faCopy}
            onClick={handleCopy}
            className={styles.copyIcon}
            title="Copy address"
          />
          {copySuccess && <span className={styles.copySuccess}>Copied!</span>}
        </div>
        <div className={styles.copyrightInfo}>©2024 BYAS All rights reserved.</div>
         
         {showMessage && (
          <div className={styles.messageOverlay}>
            <div className={styles.messageBox}>
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
              <button onClick={handleDismissMessage} className={styles.closeButton}>Close</button>
            </div>
          </div>
        )}
        </>
    ) : (
      <>
        {/* Render your images or other content here */}
        <img src="/images/site/1.png" alt="Slide 1" className={styles.slideImage} />
        <img src="/images/site/2.png" alt="Slide 2" className={styles.slideImage} />
        <img src="/images/site/3.png" alt="Slide 3" className={styles.slideImage} />
        <img src="/images/site/4.png" alt="Slide 4" className={styles.slideImage} />
        <img src="/images/site/5.png" alt="Slide 5" className={styles.slideImage} />
        <img src="/images/site/6.png" alt="Slide 6" className={styles.slideImage} />
        <img src="/images/site/7.png" alt="Slide 7" className={styles.slideImage} />
        <img src="/images/site/8.png" alt="Slide 8" className={styles.slideImage} />
        <img src="/images/site/9.png" alt="Slide 9" className={styles.slideImage} />
        <img src="/images/site/10.png" alt="Slide 10" className={styles.slideImage} />
        <img src="/images/site/11.png" alt="Slide 11" className={styles.slideImage} />
 
    
            {/* Interactive Slide 12 */}
      <div className={`<span className={styles.highlight}>$</span>{styles.interactiveSlide} <span className={styles.highlight}>$</span>{styles.slide12} <span className={styles.highlight}>$</span>{styles.slideImage}`} 
        style={{
          backgroundImage: 'url(/images/site/12.png)',
          backgroundSize: getBackgroundSize(),
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '800px',
          top: '20px',
          right: '0px',
        }}>
        <a href="https://tinyurl.com/2bytw4vh" target="_blank" rel="noreferrer" className={styles.buyArea} aria-label="Buy Area"></a>
        <a href="https://t.me/millioncryptoMilly" target="_blank" rel="noreferrer" className={styles.telegramArea} aria-label="Telegram Area"></a>
        {/* Invisible Button for Mint Ticket Action */}
        <StakingActions showMintTicket={true} />
      </div>

      <div className={styles.connectWalletButtonContainer}>
        <ConnectButton.Custom>
          {({ account, openConnectModal, openAccountModal, mounted }) => (
            <button
              onClick={account ? openAccountModal : openConnectModal}
              className={styles.customConnectButton}
              disabled={!mounted}
            >
              {account ? `${account.address.slice(0, 6)}...${account.address.slice(-4)}` : 'Connect Wallet'}
            </button>
          )}
        </ConnectButton.Custom>
      </div>
      
      <div className={`${styles.interactiveSlide} ${styles.slide13}`}
        style={{
          backgroundImage: 'url(/images/site/13.png)',
          backgroundSize: getBackgroundSize(),
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height:'800px',
        }}>
        <StakingActions showStake={true} showMintNFTFromStaking={true} showUnstake={true} />

        <a href="https://docs.millimilly.com" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink1}`}aria-label="Whitepaper" >
        </a>
        <a href="https://snowtrace.io/token/0x273819f7d9387ad17cbB77BEA32cd293F9BC8CdE?chainid=43114" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink2}`} aria-label="Avalanche">
        </a>
        <a href="https://coinmarketcap.com/dexscan/avalanche/0x42387f4fda8f0839faca0b06c79d42bc15a4a11c/" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink3}`}aria-label="Coinmarketcap" >
        </a>
        <a href="https://hundredcoin.io" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink4}`} aria-label="Hundred Coin">
        </a>
        <a href="https://tinyurl.com/2bytw4vh" target="_blank" rel="noreferrer" className={`${styles.invisibleLink} ${styles.footerLink5}`}aria-label="Trader Joe" >
        </a>
        {/*<a href="https://example.com" target="_blank" rel="noreferrer" className={`<span className={styles.highlight}>$</span>{styles.invisibleLink} <span className={styles.highlight}>$</span>{styles.footerLink6}`} aria-label="Reddit">
        </a>*/}
        {/*<a href="https://example.colink7" target="_blank" rel="noreferrer" className={`<span className={styles.highlight}>$</span>{styles.invisibleLink} <span className={styles.highlight}>$</span>{styles.footerLink7}`}aria-label="Twitter" >
        </a>*/}
     </div>
     <button onClick={scrollToTop} style={{ position: 'fixed', bottom: '50px', left: '150px', opacity: 0, cursor: 'pointer', width: '80px', height: '80px' }} aria-label="Scroll to top">Top</button>
     </>
      )}
    </div>
   );
  };

export default MainSection;
